import badgeTheme from './badge';
import buttonTheme from './button';
import { cardTheme } from './card';
import checkboxTheme from './checkbox';
import drawerTheme from './drawer';
import inputTheme from './input';
import linkTheme from './link';
import menuTheme from './menu';
import modalTheme from './modal';
import radioTheme from './radio';
import selectTheme from './select';
import tableTheme from './table';

export default {
  Input: inputTheme,
  Button: buttonTheme,
  Link: linkTheme,
  Card: cardTheme,
  Checkbox: checkboxTheme,
  Radio: radioTheme,
  Select: selectTheme,
  Badge: badgeTheme,
  Drawer: drawerTheme,
  Table: tableTheme,
  Menu: menuTheme,
  Modal: modalTheme,
};
