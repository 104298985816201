import { StarIcon } from '@chakra-ui/icons';
import { FiBookOpen, FiBox } from 'react-icons/fi';
import { GoHome } from 'react-icons/go';
import { PiChartLineDown } from 'react-icons/pi';
import { MdBarChart, MdPieChartOutlined } from 'react-icons/md';

import { NavItem } from '@/components/Layout/Navigation/types';

import { FEATURES } from './features';

export const PATHS = {
  homepage: '/',
  asset: (uniqueIdentifier) => `/asset/${uniqueIdentifier}`,
  assets: '/assets',
  adminPanel: '/admin',
  adminClients: '/admin/clients',
  adminPartners: '/admin/partners',
  adminPartner: (partnerId) => `/admin/partners/${partnerId}`,
  adminManufacturers: '/admin/manufacturers',
  adminUsers: '/admin/users',
  adminAssets: '/admin/assets',
  adminProjects: '/admin/projects',
  adminProject: (projectId) => `/admin/projects/${projectId}`,
  adminProductsUpload: (projectId) =>
    `/admin/projects/${projectId}/productsUpload`,
  adminEolAssignments: '/admin/eol-assignments',
  adminEolRequests: '/admin/eol-requests',
  adminManufacturer: (manufacturerId) =>
    `/admin/manufacturers/${manufacturerId}`,
  adminManufacturerProducts: (manufacturerId, modelId?) => {
    let path = `/admin/manufacturers/${manufacturerId}/products`;
    if (modelId) {
      path += `?modelId=${modelId}`;
    }
    return path;
  },
  adminManufacturerModels: (manufacturerId) =>
    `/admin/manufacturers/${manufacturerId}/models`,
  adminManufacturerModelsUpload: (manufacturerId) =>
    `/admin/manufacturers/${manufacturerId}/models/upload`,
  adminManufacturerBespokeModelsUpload: (manufacturerId) =>
    `/admin/manufacturers/${manufacturerId}/models/bespokeUpload`,
  adminManufacturerTagPackages: (manufacturerId) =>
    `/admin/manufacturers/${manufacturerId}/tagPackages`,
  registerAsset: (uniqueIdentifier) =>
    `/asset/register?uniqueIdentifier=${uniqueIdentifier}`,
  signIn: '/auth/signin',
  signOut: '/auth/signout',
  signUp: (token?: string) => `/auth/register${token ? `?token=${token}` : ''}`,
  manufacturerPanel: '/manufacturer',
  manufacturerModels: '/manufacturer/models',
  manufacturerProducts: '/manufacturer/products',
  dashboard: '/',
  model: (modelId) => `/models/${modelId}`,
};

export const MARKETING_PATHS = {
  termsAndConditions: 'https://www.impact-loop.com/terms-and-conditions',
  privacyPolicy: 'https://www.impact-loop.com/privacy-policy',
};

export const ROUTENAMES = {
  '': 'Homepage',
  assets: ' Manage Assets',
  admin: 'Admin Panel',
  clients: 'Manage Clients',
  users: 'Manage Users',
  signIn: 'Sign In',
  register: 'Sign Up',
  signOut: 'Sign Out',
};

export const CLIENT_ROUTES = [
  {
    label: 'My Assets',
    href: PATHS.assets,
    icon: StarIcon,
  },
];

export const MANUFACTURES_ROUTES = [
  {
    label: 'Manufacturer Panel',
    href: PATHS.manufacturerPanel,
    icon: StarIcon,
  },
];

export const PARTNER_ROUTES = [];

export const ADMIN_ROUTES = [
  {
    label: 'Admin Panel',
    href: PATHS.adminPanel,
    icon: StarIcon,
  },
];

export const MANUFACTURER_NAV_ITEMS: NavItem[] = [
  {
    label: 'Dashboard',
    href: PATHS.dashboard,
    icon: GoHome,
  },
  {
    label: 'Measure',
    icon: MdBarChart,
    href: '',
    items: [
      {
        label: 'Model level - Master code',
        href: PATHS.manufacturerModels,
      },
      {
        label: 'Product level - Mini code',
        href: PATHS.manufacturerProducts,
        enabledForFeature: FEATURES.manufacturerProducts,
      },
    ],
  },
  {
    label: 'Reduce',
    icon: PiChartLineDown,
    href: '',
    disabled: true,
    items: [
      {
        label: 'Hotspot Analysis',
        href: '',
        disabled: true,
      },
      {
        label: 'Procurement Stage',
        href: '',
        disabled: true,
      },
      {
        label: 'In-Use Stage',
        href: '',
        disabled: true,
      },
      {
        label: 'End of Life Stage',
        href: '',
        disabled: true,
      },
      {
        label: 'Marketplace',
        href: '',
        disabled: true,
      },
    ],
  },
  {
    label: 'Report',
    icon: MdPieChartOutlined,
    href: '',
    disabled: true,
    items: [
      {
        label: 'PCF report',
        href: '',
        disabled: true,
      },
    ],
  },
];

export const MOBILE_MANUFACTURER_NAV_ITEMS = [
  {
    label: 'Home',
    href: PATHS.dashboard,
    icon: GoHome,
  },
  {
    label: 'Models',
    href: PATHS.manufacturerModels,
    icon: FiBookOpen,
  },
  {
    label: 'Products',
    href: PATHS.manufacturerProducts,
    icon: FiBox,
    enabledForFeature: FEATURES.manufacturerProducts,
  },
];

export const ADMIN_NAV_ITEMS = [
  {
    label: 'Clients',
    href: PATHS.adminClients,
    icon: StarIcon,
  },
  {
    label: 'Partners',
    href: PATHS.adminPartners,
    icon: StarIcon,
  },
  {
    label: 'Manufacturers',
    href: PATHS.adminManufacturers,
    icon: StarIcon,
  },
  {
    label: 'Assets',
    href: PATHS.adminAssets,
    icon: StarIcon,
  },
  {
    label: 'Users',
    href: PATHS.adminUsers,
    icon: StarIcon,
  },
  {
    label: 'Projects',
    href: PATHS.adminProjects,
    icon: StarIcon,
  },
  {
    label: 'EOL Assignments',
    href: PATHS.adminEolAssignments,
    icon: StarIcon,
  },
  {
    label: 'EOL Requests',
    href: PATHS.adminEolRequests,
    icon: StarIcon,
  },
];
